/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { FaqCategoryShowResponse2 } from '../../models/faq-category-show-response-2';

export interface GetFaqCategoryShow$Params {
  name: string;
}

export function getFaqCategoryShow(http: HttpClient, rootUrl: string, params: GetFaqCategoryShow$Params, context?: HttpContext): Observable<StrictHttpResponse<FaqCategoryShowResponse2>> {
  const rb = new RequestBuilder(rootUrl, getFaqCategoryShow.PATH, 'get');
  if (params) {
    rb.path('name', params.name, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<FaqCategoryShowResponse2>;
    })
  );
}

getFaqCategoryShow.PATH = '/v1/faq-category/{name}';
