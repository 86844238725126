import { Route } from '@angular/router';

export const STATIC_ROUTES: Route[] = [
  {
    path: 'impressum',
    loadComponent: () =>
      import('./views/imprint/imprint.component').then((c) => c.ImprintComponent),
  },
  {
    path: 'datenschutz',
    loadComponent: () =>
      import('./views/privacy/privacy.component').then((c) => c.PrivacyComponent),
  },
  {
    path: 'coronavirus-feriencamps',
    loadComponent: () =>
      import('./views/corona-info/corona-info.component').then((c) => c.CoronaInfoComponent),
  },
  {
    path: 'agb',
    loadComponent: () => import('./views/agb/agb.component').then((c) => c.AgbComponent),
  },
  {
    path: 'wer-ist-kinder-ferienlager-com',
    loadComponent: () => import('./views/about/about.component').then((c) => c.AboutComponent),
  },
  {
    path: 'betreuer-gesucht',
    loadComponent: () =>
      import('./views/team-portal/team-portal.component').then((c) => c.TeamPortalComponent),
  },
  // todo: remove
  {
    path: 'v1/faqs_ferienlagerangebote',
    loadComponent: () => import('./views/faq/faq.component').then((c) => c.FaqComponent),
  },
  // todo: remove
  {
    path: 'v1/faq/:faq',
    loadComponent: () =>
      import('./views/faq-single/faq-single.component').then((c) => c.FaqSingleComponent),
  },
  {
    path: 'schulung',
    loadComponent: () =>
      import('./views/training/training.component').then((c) => c.TrainingComponent),
  },
  {
    path: 'mein-globus-ferienlager',
    loadComponent: () => import('./views/globus/globus.component').then((c) => c.GlobusComponent),
  },
];
