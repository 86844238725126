import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { IonicModule, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-confirm-input',
  templateUrl: './confirm-input.component.html',
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule],
})
export class ConfirmInputComponent implements OnInit {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() buttonLabel: string;
  @Input() isLoading = false;
  @Input() valid = false;
  @Input() errorMessage: string;
  @Input() successMessage: string;
  @Input() initValue = '';

  @Output() confirm = new EventEmitter<string>();
  @Output() valueChange = new EventEmitter<string>();

  form: FormGroup<{ value: FormControl<string> }>;

  constructor(private toastCtrl: ToastController) {}

  ngOnInit() {
    this.form = new FormGroup({
      value: new FormControl(this.initValue, Validators.required),
    });
  }

  async onClickConfirmButton() {
    if (!this.form.valid) {
      const toast = await this.toastCtrl.create({
        duration: 1000,
        message: 'Ungültige Eingabe',
      });
      await toast.present();
      return;
    }
    this.confirm.emit(this.form.value.value);
  }

  onInputChange() {
    this.valueChange.emit(this.form.value.value);
  }
}
