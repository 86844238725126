import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ImageComponent } from '@features/gallery/components/image/image.component';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-navigation-footer',
  templateUrl: './navigation-footer.component.html',
  styleUrls: ['./navigation-footer.component.scss'],
  imports: [CommonModule, IonicModule, ImageComponent, RouterModule],
})
export class NavigationFooterComponent {}
