import { Routes } from '@angular/router';
import { FAQ_ROUTES } from '@features/faq/faq-routes';
import { STATIC_ROUTES } from '@features/static/static-routes';

export const APP_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('@features/home/home-routes').then((m) => m.HOME_ROUTES),
  },
  {
    path: 'ferienlager/alle',
    loadChildren: () => import('@features/camp/camp-routes').then((m) => m.CAMP_ROUTES),
  },
  {
    path: 'ferienlager/-',
    loadChildren: () => import('@features/camp/camp-routes').then((m) => m.CAMP_ROUTES),
  },
  {
    path: 'ferienlager',
    loadChildren: () => import('@features/category/category-routes').then((m) => m.CATEGORY_ROUTES),
  },
  {
    path: 'aktuelles',
    loadChildren: () => import('@features/blog/blog-routes').then((m) => m.BLOG_ROUTES),
  },
  {
    path: 'bilder',
    loadChildren: () => import('./features/gallery/gallery-routes').then((m) => m.GALLERY_ROUTES),
  },
  {
    path: 'termine',
    loadChildren: () =>
      import('./features/camp-trip/camp-trip-routes').then((m) => m.CAMP_TRIP_ROUTES),
  },
  ...FAQ_ROUTES,
  ...STATIC_ROUTES,
  {
    path: 'bus',
    redirectTo: 'aktuelles/bus',
  },
  {
    path: '**',
    loadComponent: () =>
      import('./features/static/views/page-not-found/page-not-found.component').then(
        (c) => c.PageNotFoundComponent,
      ),
  },
];
