/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { FaqCategoryDto2 } from '../../models/faq-category-dto-2';
import { FaqCategoryShowResponse2 } from '../../models/faq-category-show-response-2';

export interface PatchFaqCategoryUpdate$Params {
  name: string;
      body: FaqCategoryDto2
}

export function patchFaqCategoryUpdate(http: HttpClient, rootUrl: string, params: PatchFaqCategoryUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<FaqCategoryShowResponse2>> {
  const rb = new RequestBuilder(rootUrl, patchFaqCategoryUpdate.PATH, 'patch');
  if (params) {
    rb.path('name', params.name, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<FaqCategoryShowResponse2>;
    })
  );
}

patchFaqCategoryUpdate.PATH = '/v1/faq-category/{name}';
